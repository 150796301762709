import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppState } from "../../context";
import { fetchEssentials, fetchSchools } from "../../services/profile.service";

export const useEssentials = () => {
  const { users = {} } = useSelector((store) => store.userStore);
  const { setEssentials, essentials, schools, setSchools } =
    useAppState("profile");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (schools) return;
      const response = await fetchSchools();
      setSchools(response || []);
    })();
  }, []);

  useEffect(() => {
    if (essentials || loading) return;
    (async () => {
      setLoading(true);
      const response = await fetchEssentials(users?.school_id);
      setEssentials(response);
      setLoading(false);
    })();
  }, []);

  return essentials;
};
