export const BASE_PATH = "/";

export const routes = {
  LOGIN: `${BASE_PATH}login`,
  DASHBOARD: `${BASE_PATH}dashboard`,
  BYU_DASHBOARD: `${BASE_PATH}jobs/search`,
  EDIT_EMAIL: `${BASE_PATH}edit-email`,
  BOOST_PROFILE: `${BASE_PATH}boost-profile`,
  SAVED_POSTS: `${BASE_PATH}saved-posts`,
  PRIVACY_POLICY: `${BASE_PATH}privacy-policy`,
  TERMS_AND_CONDITIONS: `${BASE_PATH}terms-and-conditions`,
  AMBASSADOR_ACCEPT_INVITE: `${BASE_PATH}ambassador-accept-invite`,
  VERIFY_SECONDARY_EMAIL: `${BASE_PATH}/secondary-email/verify`,
  WIDGET: `${BASE_PATH}university/*`,
  ONBOARDING: {
    PATH: `${BASE_PATH}onboarding/*`,
    SIGNIN: `${BASE_PATH}onboarding/signin`,
    PERSONAL: `${BASE_PATH}onboarding/personal`,
    PROFILE: `${BASE_PATH}onboarding/profile`,
    UNIVERSITY: `${BASE_PATH}onboarding/university`,
    VERIFY: `${BASE_PATH}onboarding/verify`,
    SUCCESS: `${BASE_PATH}onboarding/success`,
    NON_INVITE_VERIFY: `${BASE_PATH}onboarding/noninvite-verify`,
    NON_INVITE_PROGRAM: `${BASE_PATH}onboarding/noninvite-program`,
    NON_INVITE_SUCCESS: `${BASE_PATH}onboarding/noninvite-success`,
    FORGOT_PASSWORD: `${BASE_PATH}onboarding/forgotpassword`,
    RESET_PASSWORD: `${BASE_PATH}onboarding/resetpassword`,
  },
  JOBS: {
    PATH: `${BASE_PATH}jobs/*`,
    DASHBOARD: `${BASE_PATH}jobs`,
    SEARCH: `${BASE_PATH}jobs/search`,
    ALERT: `${BASE_PATH}jobs/job-alerts`,
    VISA: `${BASE_PATH}jobs/visa-insights`,
    COMPANY_DETAILS: `${BASE_PATH}jobs/visa-insights/company`,
    SAVED_SEARCHES: `${BASE_PATH}jobs/saved-searches`,
    SAVED_JOBS: `${BASE_PATH}jobs/saved-jobs`,
    FEATURED_JOBS: `${BASE_PATH}jobs/featured-jobs`,
    COUNTRY_INSIGHTS: `${BASE_PATH}jobs/country-insights`,
    EMPLOYER_JOBS: `${BASE_PATH}jobs/featured-jobs-portal`,
    JOB_DETAILS: `${BASE_PATH}jobs/job-details`,
    EMPLOYMENT_DATA: `${BASE_PATH}jobs/employment-data`,
  },
  LEARN: {
    PATH: `${BASE_PATH}learn/*`,
    DASHBOARD: `${BASE_PATH}learn`,
    WEBINARS_ROOT: `${BASE_PATH}learn/webinars`,
    WEBINARS: `${BASE_PATH}learn/webinars/live`,
    WEBINARS_ON_DEMAND: `${BASE_PATH}learn/webinars/on-demand`,
    WEBINARS_REGISTERED: `${BASE_PATH}learn/webinars/registered`,
    RESOURCES: `${BASE_PATH}learn/resources`,
    COURSES: `${BASE_PATH}learn/courses`,
    STUDENT_SERVICES: `${BASE_PATH}learn/student-services`,
  },
  NETWORK: {
    PATH: `${BASE_PATH}network/*`,
    DASHBOARD: `${BASE_PATH}network`,
    MESSAGES: `${BASE_PATH}network/messenger`,
    TOPICS: `${BASE_PATH}network/topics`,
    NEW_TOPIC: `${BASE_PATH}network/topics/new-topic`,
    TOPIC_PARTICIPANTS: `${BASE_PATH}network/topics/participants-list`,
    MESSENGEN: `${BASE_PATH}network/messenger`,
    NEW_MESSAGE: `${BASE_PATH}network/messenger/new-message`,
    NEW_GROUP: `${BASE_PATH}network/messenger/new-group`,
    GROUP_PARTICIPANTS: `${BASE_PATH}network/messenger/participants-list`,
    DISCOVER: `${BASE_PATH}network/discover`,
    SAVED_NETWORK: `${BASE_PATH}network/discover/saved-network`,
  },
  TOOLS: {
    PATH: `${BASE_PATH}tools/*`,
    DASHBOARD: `${BASE_PATH}tools`,
    CAREER: `${BASE_PATH}tools/career-gameplan`,
    CAREER_TEST: `${BASE_PATH}tools/career-gameplan/career-gameplan-test`,
    CHECKLIST: `${BASE_PATH}tools/checklist`,
    MEET: `${BASE_PATH}tools/meet`,
    BOOK_MEETING: `${BASE_PATH}tools/book-meeting`,
    OPT_CALCULATOR: `${BASE_PATH}tools/opt-calculator`,
  },
  PROFILE: {
    PATH: `${BASE_PATH}profile`,
    V2: `${BASE_PATH}profile/v2`,
  },
  NOTIFICATION: {
    PATH: `${BASE_PATH}notification/*`,
    DASHBOARD: `${BASE_PATH}notification`,
    SETTINGS: `${BASE_PATH}notification/settings`,
    UNSUBSCRIBE: `${BASE_PATH}notification/unsubscribe`,
  },
  HELP: {
    PATH: `${BASE_PATH}help-center/*`,
    DASHBOARD: `${BASE_PATH}help-center`,
    START: `${BASE_PATH}help-center/getting-started`,
    GUID: `${BASE_PATH}help-center/interstride-guid`,
    PROFILE: `${BASE_PATH}help-center/Profile-settings`,
    NETWORK: `${BASE_PATH}help-center/network-topic-jobs`,
    TOOLS: `${BASE_PATH}help-center/tools-resource`,
    TROUBLESHOOT: `${BASE_PATH}help-center/troubleshutting`,
  },
};
